import { styled } from "@mui/material/styles";
import Box, { BoxProps } from "@mui/material/Box";
import GlobalStyles from "@mui/material/GlobalStyles";

export const RootBox = styled(Box)<BoxProps>(({ theme }) => ({
  "*::-webkit-scrollbar": {
    height: "12px",
    width: "12px",
  },
  "*::-webkit-scrollbar-track": {
    background: "rgba(238, 238, 238, 0.2)",
  },
  "*::-webkit-scrollbar-thumb": {
    border: "3px solid rgba(0, 0, 0, 0)",
    backgroundClip: "padding-box",
    borderRadius: "12px",
    backgroundColor: `${theme.palette.primary.main}90`,
    boxShadow: "inset 0 0 0 1px rgba(0, 0, 0, 0.025)",
    transition: "background-color 0.3s",
    "&:hover": {
      backgroundColor: `${theme.palette.primary.main}`,
    },
  },
  "*::-webkit-scrollbar-button": {
    height: 0,
    width: 0,
    display: "none",
  },
  "*::-webkit-scrollbar-corner": {
    backgroundColor: "transparent",
  },
}));

export const GlobalScrollbarStyles = ({ theme }: { theme: any }) => {
  return (
    <GlobalStyles
      styles={{
        "*::-webkit-scrollbar": {
          height: "12px",
          width: "12px",
        },
        "*::-webkit-scrollbar-track": {
          background: "rgba(238, 238, 238, 0.2)",
        },
        "*::-webkit-scrollbar-thumb": {
          border: "3px solid rgba(0, 0, 0, 0)",
          backgroundClip: "padding-box",
          borderRadius: "12px",
          backgroundColor: `${theme.palette.primary.main}90`,
          boxShadow: "inset 0 0 0 1px rgba(0, 0, 0, 0.025)",
          transition: "background-color 0.3s",
        },
        "*::-webkit-scrollbar-thumb:hover": {
          backgroundColor: `${theme.palette.primary.main}`,
        },
        "*::-webkit-scrollbar-button": {
          height: 0,
          width: 0,
          display: "none",
        },
        "*::-webkit-scrollbar-corner": {
          backgroundColor: "transparent",
        },
        body: {
          margin: 0,
          padding: 0,
          overflowY: "scroll", // Ensure scrollbar is visible on the body
        },
      }}
    />
  );
};
