const customerBaseUrl = "/backend/creator-digipopo/customer";

export const endPoint = {
  // Customer
  customer: {
    brands: `${customerBaseUrl}/brands`,
    brandsDetail: (brandId: string) => `${customerBaseUrl}/brands/${brandId}`,
    company: (initial: string) => `${customerBaseUrl}/company/${initial}`,
    companyStreamConfigAlert: `${customerBaseUrl}/company-stream/config/alert`,
    orders: `${customerBaseUrl}/orders`,
    ordersDetail: (orderId: string) => `${customerBaseUrl}/orders/${orderId}`,
    paymentConfigs: `${customerBaseUrl}/payment-configs`,
    products: `${customerBaseUrl}/products`,
    usernameCheck: `${customerBaseUrl}/username-check`,
  },
};
