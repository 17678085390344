import { FailedLoadPagesProps } from "./FailedLoadPages.types";
import { CenteredColumnFullBox } from "../../styles";
import { FailedToLoadMessage } from "./FailedLoagPages.styles";
import { CustomButton } from "components";
import { ErrorPages } from "assets/icons";

export default function FailedLoadPages(props: FailedLoadPagesProps) {
  return (
    <CenteredColumnFullBox sx={{ flex: 1, height: "auto", minHeight: "70vh" }}>
      <ErrorPages width={260} height={260} />

      <FailedToLoadMessage>
        {props.message ?? "Terjadi masalah jaringan :("}
      </FailedToLoadMessage>

      {typeof props.onRefresh === "function" && (
        <CustomButton
          buttonProps={{
            onClick: props.onRefresh,
          }}
        >
          Refresh
        </CustomButton>
      )}
    </CenteredColumnFullBox>
  );
}
