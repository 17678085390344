import { useMemo, useState } from "react";
import { createTheme } from "@mui/material/styles";
import themeSettings, { type ColorType } from "theme/muiTheme";

const useTheme = () => {
  const [colorTheme, setColorTheme] = useState<ColorType>({
    background: "#FFFFFF",
    backgroundCard: "#53096e",
    primary: "#819ce7",
    secondary: "#53096e",
    textPrimary: "#000000",
    textSecondary: "#819ce7",
  });

  // Setiap ada perubahan colorTheme baru diubah themeSettings nya
  const theme: any = useMemo(
    () => createTheme(themeSettings(colorTheme)),
    [colorTheme]
  );

  return [theme, setColorTheme];
};

export default useTheme;
