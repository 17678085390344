import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import "./LoaderPageFetch.scss";

function LoaderPageFetch() {
  return (
    <Box className="background-loader">
      <Box className="circular-wrapper">
        <CircularProgress size={60} />
        <Typography color={"text.secondary"}>Loading...</Typography>
      </Box>
    </Box>
  );
}

export default LoaderPageFetch;
