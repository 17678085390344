import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import { createApiBase } from "config/api.config";
import appSlice from "slices/app.slice";
import companySlice from "slices/company.slice";

const rootReducer = combineReducers({
  app: appSlice,
  company: companySlice,
  [createApiBase.reducerPath]: createApiBase.reducer,
});

const middleware = [createApiBase.middleware];

const Store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(...middleware),
});

export default Store;

export type RootState = ReturnType<typeof Store.getState>;

export type AppDispatch = typeof Store.dispatch;
