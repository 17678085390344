import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CompanyData } from "api/customer";

// Define the initial state using that type
const initialState: Partial<CompanyData> = {};

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setInitial: (state, action: PayloadAction<string>) => {
      state.initial = action.payload;
    },
    setCompanyData: (_, action: PayloadAction<Partial<CompanyData>>) => {
      return { ...action.payload };
    },
  },
});

// actions
export const { setInitial, setCompanyData } = companySlice.actions;

// reducer
export default companySlice.reducer;
