import loadable from "@loadable/component";
import { Navigate, Route, Routes } from "react-router-dom";

import { LoaderPage, PageNotFound } from "components";
import { menuUrl } from "constants/";
import { RouteWrapper } from "config/route.config";

const Home = loadable(() => import("pages/home"), { fallback: <LoaderPage /> });
const CekPesanan = loadable(() => import("pages/cek-pesanan"), {
  fallback: <LoaderPage />,
});
const DetailBrand = loadable(() => import("pages//detail-brand"), {
  fallback: <LoaderPage />,
});
const RiwayatTransaksi = loadable(() => import("pages/riwayat-transaksi"), {
  fallback: <LoaderPage />,
});

const initial = "/:initial";
const base = `${initial}`;
const cekPesanan = `${initial}${menuUrl.cekPesanan}`;
const cekPesananDetail = `${initial}${menuUrl.cekPesananDetail}`;
const detailBrand = `${initial}${menuUrl.detailBrand}`;
const riwayatPesanan = `${initial}${menuUrl.riwayatPesanan}`;

const routes = (
  <Routes>
    <Route path={initial} element={<RouteWrapper />}>
      <Route path={base} element={<Home />} />
      <Route path={cekPesanan} element={<CekPesanan />} />
      <Route path={cekPesananDetail} element={<CekPesanan />} />
      <Route path={detailBrand} element={<DetailBrand />} />
      <Route path={riwayatPesanan} element={<RiwayatTransaksi />} />

      <Route path="*" element={<Navigate replace to={menuUrl.base} />} />
    </Route>

    <Route path="*" element={<PageNotFound />} />
  </Routes>
);

export default routes;
