export const menuUrl = {
  base: "/",
  cekPesanan: "/pesanan",
  cekPesananDetail: "/pesanan/:id",
  detailBrand: "/:brandIdUrl",
  riwayatPesanan: "/riwayat-pesanan",

  // With Initial for redirecting
  baseInitial: (initial: string) => `/${initial}`,
  cekPesananInitial: (initial: string) => `/${initial}/pesanan`,
  cekPesananDetailInitial: (initial: string, id: string) =>
    `/${initial}/pesanan/${id}`,
  detailBrandInitial: (initial: string, brandIdUrl: string) =>
    `/${initial}/${brandIdUrl}`,
  riwayatPesananInitial: (initial: string) => `/${initial}/riwayat-pesanan`,
};
