import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import "./index.scss";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import App from "App";
import Store from "store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={Store}>
    <App />
  </Provider>
);

serviceWorkerRegistration.register();
