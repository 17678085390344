import { BaseQueryFn, createApi } from "@reduxjs/toolkit/query/react";
import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  CancelTokenSource,
} from "axios";
import {
  forbiddenAccess,
  internetConnectionProblem,
  responseTimeout,
} from "constants/";
// import Store from "store";

interface ExtendedAxiosRequestConfig extends AxiosRequestConfig {
  sourceToken?: CancelTokenSource;
  /**
   * default, false. Used to attach timeout in request
   */
  useAxiosDefaultTimeout?: boolean;
}

interface BaseHeadersType {
  Accept: string;
  "Content-Type": string;
  "Access-Control-Allow-Origin": string;
  "Access-Control-Allow-Headers": string;
  "Access-Control-Allow-Credentials": string;
  [key: string]: string;
}

const baseHeaders: BaseHeadersType = {
  Accept:
    "application/json, application/pdf, application/octet-stream, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Headers": "*",
  "Access-Control-Allow-Credentials": "true",
};

function getCompanyId() {
  const companyId = localStorage.getItem("creator:companyId") ?? "";

  return companyId;
}

// function getUserToken() {
//   const getReduxState = Store.getState();

//   if (!getReduxState) return "";

//   const auth = getReduxState.authentication;

//   if (!auth) return "";

//   const token = auth.sessionId;

//   if (token.length === 0) return "";
//   return `Bearer ${token}`;
// }

// function getUserSessionSecurityToken() {
//   const getReduxState = Store.getState();

//   if (!getReduxState) return "";

//   const app = getReduxState.app;

//   if (!app) return "";

//   const token = app.sessionSecurityCodeId;

//   if (token.length === 0) return "";
//   return token;
// }

function getAuthenticatedHeader() {
  // const token = getUserToken();
  // const sessionSecurityToken = getUserSessionSecurityToken();
  const companyId = getCompanyId();

  let headers: BaseHeadersType = baseHeaders;

  headers = { ...headers, companyId };

  // if (token.length > 0) headers = { ...headers, Authorization: token };
  // if (sessionSecurityToken.length > 0)
  //   headers = { ...headers, "Re-Authentication": sessionSecurityToken };

  return headers;
}

async function doFetch({ headers, ...rest }: ExtendedAxiosRequestConfig) {
  if (!window.navigator.onLine) throw new Error(internetConnectionProblem);

  let _headers: any = getAuthenticatedHeader();

  if (headers) _headers = { ..._headers, ...headers };

  const abort = rest.sourceToken || axios.CancelToken.source();
  const timeout = rest.timeout || 60000;

  let _setTimeout: NodeJS.Timeout | undefined;

  if (!rest.useAxiosDefaultTimeout) delete rest.timeout;

  if (timeout !== undefined) {
    _setTimeout = setTimeout(() => {
      abort.cancel(responseTimeout);
    }, timeout);
  }

  rest.cancelToken = abort.token;

  try {
    const result = await axios({
      headers: _headers,
      baseURL: process.env.REACT_APP_API_URL,
      validateStatus: (status) => status >= 200 && status <= 299,
      ...rest,
    });

    if (_setTimeout) clearTimeout(_setTimeout);

    return result;
  } catch (axiosError) {
    const err = axiosError as AxiosError;
    console.log("error : ", err);
    if (_setTimeout) clearTimeout(_setTimeout);

    // here all status < 200 && status >= 300 handled
    // here is info about http status code: https://developer.mozilla.org/en-US/docs/Web/HTTP/Status

    let errorMessage = "";

    // custom message if request timeout. In order to make the message more user friendly
    if (err.response?.status === 403) errorMessage = forbiddenAccess;

    const throwObject = {
      message: errorMessage ?? "",
      code: err.response?.status ?? 0,
      data: err.response?.data ?? "",
    };

    throw new Error(JSON.stringify(throwObject) as string);
  }
}

async function doFetchNoAuth({ headers, ...rest }: ExtendedAxiosRequestConfig) {
  if (!window.navigator.onLine) throw new Error(internetConnectionProblem);

  try {
    const result = await axios({
      headers: { ...headers, ...baseHeaders },
      baseURL: process.env.REACT_APP_API_URL,
      validateStatus: (status) => status >= 200 && status <= 299,
      ...rest,
    });

    return result;
  } catch (axiosError) {
    const err = axiosError as AxiosError;

    // here all status < 200 && status >= 300 handled
    // here is info about http status code: https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
    let errorMessage =
      err.response && err.response.data ? err.response.data : err.message;
    errorMessage = errorMessage || JSON.stringify(err);

    if (typeof errorMessage !== "string")
      errorMessage = JSON.stringify(errorMessage);

    // custom message if request timeout. In order to make the message more user friendly
    if (err.code === "ECONNABORTED") errorMessage = responseTimeout;
    if (err.response?.status === 403) errorMessage = forbiddenAccess;

    throw new Error(errorMessage as string);
  }
}

/**
 * axiosBaseQuery used for RTK Query
 */
const axiosBaseQuery =
  (): BaseQueryFn<ExtendedAxiosRequestConfig, AxiosResponse, unknown> =>
  async (config) => {
    return doFetch(config);
  };

/**
 * HttpServices used if need direct call to API without need to use hooks
 */
export const HttpServices = {
  get<T = any>(config: ExtendedAxiosRequestConfig): Promise<AxiosResponse<T>> {
    return doFetch({ ...config, method: "GET" });
  },
  post<T = any>(config: ExtendedAxiosRequestConfig): Promise<AxiosResponse<T>> {
    return doFetch({ ...config, method: "POST" });
  },
  patch<T = any>(
    config: ExtendedAxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return doFetch({ ...config, method: "PATCH" });
  },
  put<T = any>(config: ExtendedAxiosRequestConfig): Promise<AxiosResponse<T>> {
    return doFetch({ ...config, method: "PUT" });
  },
  delete<T = any>(
    config: ExtendedAxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return doFetch({ ...config, method: "DELETE" });
  },
};

/**
 * HttpServices used if need direct call to API without need to use hooks
 */
export const HttpServicesNoAuth = {
  get<T = any>(config: ExtendedAxiosRequestConfig): Promise<AxiosResponse<T>> {
    return doFetchNoAuth({ ...config, method: "GET" });
  },
  post<T = any>(config: ExtendedAxiosRequestConfig): Promise<AxiosResponse<T>> {
    return doFetchNoAuth({ ...config, method: "POST" });
  },
  patch<T = any>(
    config: ExtendedAxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return doFetchNoAuth({ ...config, method: "PATCH" });
  },
  put<T = any>(config: ExtendedAxiosRequestConfig): Promise<AxiosResponse<T>> {
    return doFetchNoAuth({ ...config, method: "PUT" });
  },
  delete<T = any>(
    config: ExtendedAxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return doFetchNoAuth({ ...config, method: "DELETE" });
  },
};

export const createApiBase = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: () => ({}),
  keepUnusedDataFor: 30,
  tagTypes: [],
});
