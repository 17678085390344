import { CenteredColumnFullBox } from "../../styles";
import { Typography } from "@mui/material";

export default function PageNotFound() {
  return (
    <CenteredColumnFullBox sx={{ height: "100vh" }}>
      <Typography sx={{ fontSize: "64px", fontFamily: "Poppins-SemiBold" }}>
        404
      </Typography>
      <Typography sx={{ fontSize: "20px" }}>Halaman tidak ditemukan</Typography>
    </CenteredColumnFullBox>
  );
}
