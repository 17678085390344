/* eslint-disable react-hooks/exhaustive-deps */
import { useLayoutEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import { useLazyGetCompanyDataQuery } from "api/customer";
import { Footer, LoaderPageFetch, Navbar, PageNotFound } from "components";
import { useAppDispatch, useAppSelector } from "hooks";
import useTheme from "hooks/useTheme";
import routes from "routes";
import { setIsLoadingPage } from "slices/app.slice";
import { setCompanyData } from "slices/company.slice";
import "./App.scss";
import { GlobalScrollbarStyles, RootBox } from "App.styles";
import { Helmet } from "react-helmet";

export default function App() {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.app.isLoading);
  const initial = useAppSelector((state) => state.company.initial);
  const platform = useAppSelector((state) => state.company.platform);

  const [theme, setColorTheme] = useTheme();

  const [getCompanyData] = useLazyGetCompanyDataQuery();

  const [isCompanyNotFound, setIsCompanyNotFound] = useState<boolean>(false);

  const fetchCompanyData = async () => {
    if (!initial) return;

    try {
      dispatch(setIsLoadingPage(true));
      const res = await getCompanyData(initial).unwrap();
      setColorTheme(res.platform.color);
      dispatch(setCompanyData(res));
      localStorage.setItem("creator:companyId", res.id);
    } catch (error) {
      setIsCompanyNotFound(true);
    } finally {
      dispatch(setIsLoadingPage(false));
    }
  };

  useLayoutEffect(() => {
    if (!initial) return;

    fetchCompanyData();
  }, [initial]);

  if (isCompanyNotFound) {
    return <PageNotFound />;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalScrollbarStyles theme={theme} />
      <BrowserRouter>
        <RootBox>
          <Helmet>
            <title>
              {platform?.name ?? ""} - Top Up Game Lengkap, Murah, dan Cepat
            </title>
            <meta
              name="description"
              content={`Website Resmi ${platform?.name ?? ""}`}
            />
            <link rel="icon" href={platform?.favicon ?? ""} />
            <link rel="apple-touch-icon" href={platform?.favicon ?? ""} />
          </Helmet>
          {initial && <Navbar />}
          {routes}
          {initial && <Footer />}
        </RootBox>
      </BrowserRouter>

      <Toaster />
      {isLoading && <LoaderPageFetch />}
    </ThemeProvider>
  );
}
