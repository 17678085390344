import CircularProgress from "@mui/material/CircularProgress";

import "./LoaderPage.scss";
import { BasedPages } from "components";
import { CenteredColumnBox } from "styles";

function LoaderPage() {
  return (
    <BasedPages>
      <CenteredColumnBox sx={{ minHeight: "calc(100vh - 10rem)" }}>
        <CircularProgress size={60} />
      </CenteredColumnBox>
    </BasedPages>
  );
}

export default LoaderPage;
