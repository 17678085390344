/* eslint-disable react-hooks/exhaustive-deps */
import { useAppDispatch, useAppSelector } from "hooks";
import { useLayoutEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { setInitial } from "slices/company.slice";

export const RouteWrapper = () => {
  const { initial } = useParams();

  const dispatch = useAppDispatch();
  const initialSelector = useAppSelector((state) => state.company.initial);

  useLayoutEffect(() => {
    if (initialSelector || !initial) return;

    dispatch(setInitial(initial));
  }, [initial]);

  return <Outlet />;
};
