import { ThemeOptions } from "@mui/material";
import "./fonts.scss";

export type ColorType = {
  background: string;
  backgroundCard: string;
  primary: string;
  secondary: string;
  textPrimary: string;
  textSecondary: string;
};

const themeSettings = ({
  background,
  backgroundCard,
  primary,
  secondary,
  textPrimary,
  textSecondary,
}: ColorType): ThemeOptions => {
  return {
    palette: {
      primary: {
        main: primary,
      },
      secondary: {
        main: secondary,
      },
      text: {
        primary: textPrimary,
        secondary: textSecondary,
        disabled: "#d3d3d3",
      },
      background: {
        default: background,
        paper: backgroundCard,
      },
      info: {
        main: "#cfd2d4",
      },
      warning: {
        main: "#FFD027",
      },
      success: {
        main: "#38ee78",
      },
      error: {
        main: "#fb604f",
      },
      common: {
        black: "#000000",
        white: "#ffffff",
      },
    },
    typography: {
      fontFamily: ["Poppins-Regular"].join(","),
      h1: {
        fontFamily: ["Poppins-SemiBold"].join(","),
        fontSize: "2rem",
      },
      h2: {
        fontFamily: ["Poppins-SemiBold"].join(","),
        fontSize: "1.5rem",
      },
      h3: {
        fontFamily: ["Poppins-Medium"].join(","),
        fontSize: "1.25rem",
      },
      h4: {
        fontFamily: ["Poppins-Medium"].join(","),
        fontSize: "1rem",
      },
      h5: {
        fontFamily: ["Poppins-Regular"].join(","),
        fontSize: "0.875rem",
      },
      h6: {
        fontFamily: ["Poppins-Regular"].join(","),
        fontSize: "0.6875rem",
      },
    },
    components: {
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            width: "auto", // Disable default width
            height: "auto", // Disable default height
          },
        },
      },
    },
  };
};

export default themeSettings;
