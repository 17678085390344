import { lazy } from "react";

export const CustomerService = lazy(() => import("./CustomerService"));
export const ErrorPages = lazy(() => import("./ErrorPages"));
export const FastOrder = lazy(() => import("./FastOrder"));
export const FailedToLoad = lazy(() => import("./FailedToLoad"));
export const HargaBersaing = lazy(() => import("./HargaBersaing"));
export const Promo = lazy(() => import("./Promo"));
export const Terpercaya = lazy(() => import("./Terpercaya"));
export const Timing = lazy(() => import("./Timing"));

export { default as LoaderPage } from "./LoaderPage.json";
export { default as SearchIcon } from "./SearchIcon";
