import { Link } from "react-router-dom";

import {
  BrandContainer,
  BrandTitle,
  CustomPaper,
  ImageBrand,
} from "./CustomCardBrandProduct.styles";
import { CustomCardBrandProductProps } from "./CustomCardBrandProduct.types";
import { menuUrl } from "constants/";
import { transformUnderscoreToUrl } from "utils";
import { useAppSelector } from "hooks";

function CustomCardBrandProduct({
  image,
  title,
  brandId,
}: CustomCardBrandProductProps) {
  const initial = useAppSelector((state) => state.company.initial);

  return (
    <Link
      to={menuUrl.detailBrandInitial(
        initial ?? "",
        transformUnderscoreToUrl(brandId)
      )}
      style={{ textDecoration: "none" }}
    >
      <CustomPaper>
        <ImageBrand src={image} alt={image} />
        <BrandContainer>
          <BrandTitle>{title}</BrandTitle>
        </BrandContainer>
      </CustomPaper>
    </Link>
  );
}

export default CustomCardBrandProduct;
